
      body.loading .main-content, body.loading .login-overlay {
        display: none;
      }

    body.loading .loading-screen {
        position: absolute;
        color: #fff;
        background-color: #000;
        justify-content: center;
        align-items: center;
        height: 100vh;
        padding: 20vw;
        display: flex;
        z-index: 5000000;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
    }
     
      .loading-screen {
        display: none;
      }
    
      .loading-logo {
        max-width: 150px;
        margin-bottom: 20px;
      }
    
      .loading-text {
        font-size: 1.5rem;
        margin-bottom: 20px;
      }